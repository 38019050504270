.user,
.Me {
  display: block;
  float: right;
  width: 80%;
  font-style: italic;
  clear: both;
  color: #3471eb;
}

.model,
.You {
  display: block;
  float: left;
  width: 80%;
  clear: both;
}
